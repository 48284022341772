import {
  Create,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  required,
} from "react-admin";

const TagCreateActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const TagCreate = (props) => (
  <Create actions={<TagCreateActions />} {...props}>
    <SimpleForm>
      <TextInput source="title" validate={[required()]} fullWidth />
    </SimpleForm>
  </Create>
);

export default TagCreate;
