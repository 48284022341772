import { useState, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  SimpleFormIterator,
  TopToolbar,
  ListButton,
  ShowButton,
  CreateButton,
  required,
} from "react-admin";
import { useFormState } from "react-final-form";

const QuestionEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const ParagraphInput = (props) => {
  const { values } = useFormState();
  const [paragraphChoices, setParagraphChoices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!values.quiz) {
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/paragraphs?limit=9999&offset=0&sort=created_at&order=DESC&quiz_id=${values.quiz}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((datas) =>
          datas.map((data) => {
            data.content = data.content
              .replace(/<[^>]*>?/gm, "")
              .substring(0, 200);
            return data;
          })
        );
      setParagraphChoices(response);
    };
    fetchData();
    return setParagraphChoices([]); // clean up for the previous useEffect run
  }, [values.quiz]);

  return (
    <AutocompleteInput
      choices={values.quiz ? paragraphChoices : []}
      {...props}
    />
  );
};

const QuestionEdit = (props) => {
  const [quizChoices, setQuizChoices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/quizzes?limit=9999&offset=0&sort=created_at&order=DESC`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth")}`,
          },
        }
      ).then((response) => response.json());
      setQuizChoices(response);
    };
    fetchData();
  }, []);

  return (
    <Edit actions={<QuestionEditActions />} {...props}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <AutocompleteInput
          source="quiz"
          optionText="title"
          optionValue="id"
          label="Quiz"
          choices={quizChoices}
          validate={[required()]}
        />
        <TextInput
          source="prompt"
          multiline
          fullWidth
          validate={[required()]}
        />
        <TextInput source="explanation" multiline fullWidth />
        <ParagraphInput
          source="paragraph"
          optionText="content"
          optionValue="id"
          label="Paragraph"
          fullWidth
        />
        <ArrayInput source="answers">
          <SimpleFormIterator>
            <TextInput
              source="prompt"
              label="Prompt"
              validate={[required()]}
              fullWidth
            />
            <BooleanInput source="is_correct" label="Is Correct" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default QuestionEdit;
