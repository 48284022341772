import {
  Create,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  required,
} from "react-admin";

const SubjectCreateActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const SubjectCreate = (props) => (
  <Create actions={<SubjectCreateActions />} {...props}>
    <SimpleForm>
      <TextInput source="title" validate={[required()]} fullWidth />
    </SimpleForm>
  </Create>
);

export default SubjectCreate;
