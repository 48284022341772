import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ArrayField,
  DateField,
  SingleFieldList,
  ChipField,
  TextInput,
  SearchInput,
  EditButton,
  ShowButton,
  CloneButton,
} from "react-admin";
import ListActions from "./ListActions";

const filters = [
  <SearchInput source="quiz" alwaysOn />,
  <TextInput label="Description" source="description" />,
  <TextInput label="Subject" source="subject" />,
];

const QuizList = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      actions={
        <ListActions sortFields={["id", "quiz", "subject", "created_at"]} />
      }
      filters={filters}
      debounce={700}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid optimized rowClick="show">
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="description" sortable={false} />
        <TextField
          source="time"
          label="Time (mins)"
          emptyText="null"
          sortable={false}
        />
        <ChipField
          source="subject_entity.title"
          label="Subject"
          color="primary"
          sortBy="subject"
        />
        <ChipField source="type" color="primary" />
        <ChipField source="status" color="primary" />
        <ArrayField source="tag_entity" label="Tag" sortable={false}>
          <SingleFieldList>
            <ChipField source="title" color="primary" />
          </SingleFieldList>
        </ArrayField>
        <DateField
          source="created_at"
          label="Created At"
          showTime
          locales="fr-FR"
        />
        <EditButton />
        <ShowButton />
        <CloneButton />
      </Datagrid>
    </List>
  );
};

export default QuizList;
