import {
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  ChipField,
  DateField,
  SingleFieldList,
  TopToolbar,
  ListButton,
  EditButton,
  CreateButton,
} from "react-admin";

const QuizShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <CreateButton basePath={basePath} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const QuizShow = (props) => {
  return (
    <Show actions={<QuizShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField label="Id" source="id" />
        <TextField label="Title" source="title" />
        <TextField label="Description" source="description" />
        <TextField
          source="time"
          label="Time (mins)"
          emptyText="null"
          sortable={false}
        />
        <ChipField
          source="subject_entity.title"
          label="Subject"
          color="primary"
          sortBy="subject"
        />
        <ChipField label="Type" source="type" color="primary" />
        <ChipField label="Status" source="status" color="primary" />
        <ArrayField label="Tags" source="tag_entity">
          <SingleFieldList>
            <ChipField source="title" color="primary" />
          </SingleFieldList>
        </ArrayField>
        <DateField
          source="created_at"
          label="Created At"
          showTime
          locales="fr-FR"
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default QuizShow;
