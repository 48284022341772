import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ChipField,
  SearchInput,
  TextInput,
  EditButton,
  ShowButton,
  CloneButton,
  useRecordContext,
} from "react-admin";
import ListActions from "./ListActions";
import parse from "html-react-parser";

const filters = [
  <SearchInput source="prompt" alwaysOn />,
  <TextInput label="Quiz Title" source="quiz" />,
  <TextInput label="Paragraph" source="paragraph" />,
];

const QuestionPanel = () => {
  const record = useRecordContext();
  return (
    <>
      {record.paragraph_entity?.content ? (
        <div>
          {parse("<u><b>Paragraph:</b></u>")}
          {parse(record.paragraph_entity.content)}
        </div>
      ) : null}
      {record.explanation ? (
        <div>
          {parse("<u><b>Explanation:</b></u>")}
          {parse(record.explanation)}
        </div>
      ) : null}
    </>
  );
};

const ContentField = ({ source }) => {
  const record = useRecordContext();
  if (record && record[source]) {
    return (
      <div style={{ width: "250px" }}>
        {parse(`${record[source].substring(0, 150)}...`)}
      </div>
    );
  }
  return null;
};

const ParagraphField = () => {
  const record = useRecordContext();
  if (record.paragraph_entity?.content) {
    return (
      <div style={{ width: "250px" }}>
        {parse(`${record.paragraph_entity.content.substring(0, 150)}...`)}
      </div>
    );
  }
  return null;
};

const QuestionList = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    actions={<ListActions sortFields={["id", "quiz", "created_at"]} />}
    filters={filters}
    debounce={700}
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid optimized rowClick="show" expand={<QuestionPanel />}>
      <TextField source="id" />
      <TextField source="quiz_entity.title" label="Quiz Title" sortBy="quiz" />
      <ChipField
        source="quiz_entity.type"
        label="Quiz Type"
        color="primary"
        sortable={false}
      />
      <ContentField source="prompt" label="Prompt" sortable={false} />
      <ParagraphField
        source="paragraph_entity.content"
        label="Paragraph"
        sortBy="paragraph"
      />
      <DateField
        source="created_at"
        label="Created At"
        showTime
        locales="fr-FR"
      />
      <EditButton />
      <ShowButton />
      <CloneButton />
    </Datagrid>
  </List>
);

export default QuestionList;
