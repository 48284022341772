import { useState, useEffect } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  SimpleFormIterator,
  TopToolbar,
  ListButton,
  useNotify,
  useRefresh,
  required,
} from "react-admin";
import { useFormState } from "react-final-form";

const QuestionCreateActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const ParagraphInput = (props) => {
  const { values } = useFormState();
  const [paragraphChoices, setParagraphChoices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!values.quiz) {
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/paragraphs?limit=9999&offset=0&sort=created_at&order=DESC&quiz_id=${values.quiz}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((datas) =>
          datas.map((data) => {
            data.content = data.content
              .replace(/<[^>]*>?/gm, "")
              .substring(0, 200);
            return data;
          })
        );
      setParagraphChoices(response);
    };
    fetchData();
    return setParagraphChoices([]); // clean up for the previous useEffect run
  }, [values.quiz]);

  return (
    <AutocompleteInput
      choices={values.quiz ? paragraphChoices : []}
      {...props}
    />
  );
};

const QuestionCreate = (props) => {
  const [quizChoices, setQuizChoices] = useState([]);
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify(`Question saved successfully`);
    refresh();
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/quizzes?limit=9999&offset=0&sort=created_at&order=DESC`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth")}`,
          },
        }
      ).then((response) => response.json());
      setQuizChoices(response);
    };
    fetchData();
  }, []);

  return (
    <Create
      actions={<QuestionCreateActions />}
      onSuccess={onSuccess}
      {...props}
    >
      <SimpleForm>
        <AutocompleteInput
          source="quiz"
          optionText="title"
          optionValue="id"
          label="Quiz"
          choices={quizChoices}
          validate={[required()]}
        />
        <TextInput
          source="prompt"
          multiline
          fullWidth
          validate={[required()]}
        />
        <TextInput source="explanation" multiline fullWidth />
        <ParagraphInput
          source="paragraph"
          optionText="content"
          optionValue="id"
          label="Paragraph"
          fullWidth
        />
        <ArrayInput source="answers">
          <SimpleFormIterator>
            <TextInput
              source="prompt"
              label="Prompt"
              fullWidth
              validate={[required()]}
            />
            <BooleanInput
              source="is_correct"
              label="Is Correct"
              defaultValue={false}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default QuestionCreate;
