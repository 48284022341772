import * as React from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import SortIcon from "@material-ui/icons/Sort";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useListSortContext } from "react-admin";

const SortButton = ({ fields }) => {
  // currentSort is an object { field, order } containing the current sort
  // setSort is a callback (field, order) => void allowing to change the sort field and order
  const { currentSort, setSort } = useListSortContext();
  // open/closed state for dropdown
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeSort = (event) => {
    const field = event.currentTarget.dataset.sort;
    setSort(
      field,
      field === currentSort.field ? inverseOrder(currentSort.order) : "ASC"
    );
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleClick}
        startIcon={<SortIcon />}
        endIcon={<ArrowDropDownIcon />}
        size="small"
      >
        {`Sort by ${currentSort.field} ${currentSort.order}`}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {fields.map((field) => (
          <MenuItem
            onClick={handleChangeSort}
            // store the sort field in the element dataset to avoid creating a new click handler for each item (better for performance)
            data-sort={field}
            key={field}
          >
            {`Sort by ${field} ${
              currentSort.field === field
                ? inverseOrder(currentSort.order)
                : "ASC"
            }`}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const inverseOrder = (sort) => (sort === "ASC" ? "DESC" : "ASC");

export default SortButton;
