import { useState, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SelectInput,
  SimpleFormIterator,
  TopToolbar,
  ListButton,
  ShowButton,
  CreateButton,
} from "react-admin";

const UserEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const UserEdit = (props) => {
  const [roleChoices, setRoleChoices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/roles?limit=9999&offset=0`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth")}`,
          },
        }
      );
      const data = await response.json();
      setRoleChoices(data);
    };
    fetchData();
  }, []);

  return (
    <Edit actions={<UserEditActions />} {...props}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <TextInput disabled label="Username" source="username" />
        <TextInput disabled label="Email" source="email" />
        <TextInput disabled label="Avatar" source="avatar" />
        <ArrayInput source="roles">
          <SimpleFormIterator>
            <SelectInput source="id" label="Role name" choices={roleChoices} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
