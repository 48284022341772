import {
  Create,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  required,
} from "react-admin";

const RoleCreateActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const RoleCreate = (props) => (
  <Create actions={<RoleCreateActions />} {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        label="Role name"
        validate={[required()]}
        fullWidth
      />
    </SimpleForm>
  </Create>
);

export default RoleCreate;
