import {
  Show,
  SimpleShowLayout,
  TextField,
  ChipField,
  TopToolbar,
  ListButton,
  EditButton,
  CreateButton,
} from "react-admin";

const RoleShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <CreateButton basePath={basePath} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const RoleShow = (props) => {
  return (
    <Show actions={<RoleShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField label="Id" source="id" />
        <ChipField source="name" color="primary" />
      </SimpleShowLayout>
    </Show>
  );
};

export default RoleShow;
