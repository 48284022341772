import {
  Show,
  SimpleShowLayout,
  TextField,
  ChipField,
  RichTextField,
  DateField,
  TopToolbar,
  ListButton,
  EditButton,
  CreateButton,
} from "react-admin";

const ParagraphShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <CreateButton basePath={basePath} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const ParagraphShow = (props) => {
  return (
    <Show actions={<ParagraphShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField label="Id" source="id" />
        <TextField source="quiz_entity.title" label="Quiz Title" />
        <ChipField
          label="Quiz Type"
          source="quiz_entity.type"
          color="primary"
        />
        <ChipField source="type" color="primary" />
        <RichTextField source="content" />
        <DateField
          source="created_at"
          label="Created At"
          showTime
          locales="fr-FR"
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default ParagraphShow;
