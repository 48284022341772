import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  DateField,
  TextInput,
  EditButton,
  ShowButton,
  CloneButton,
} from "react-admin";
import ListActions from "./ListActions";

const filters = [<TextInput label="Search" source="title" alwaysOn />];

const SubjectList = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    actions={<ListActions sortFields={["id", "title", "created_at"]} />}
    filters={filters}
    debounce={700}
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid optimized rowClick="show">
      <TextField source="id" />
      <ChipField source="title" color="primary" />
      <DateField
        source="created_at"
        label="Created At"
        showTime
        locales="fr-FR"
      />
      <EditButton />
      <ShowButton />
      <CloneButton />
    </Datagrid>
  </List>
);

export default SubjectList;
