import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  SearchInput,
  EditButton,
  ShowButton,
  CloneButton,
} from "react-admin";
import ListActions from "./ListActions";

const filters = [<SearchInput source="name" alwaysOn />];

const RoleList = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    actions={<ListActions sortFields={["id", "name"]} />}
    filters={filters}
    debounce={700}
    sort={{ field: "name", order: "ASC" }}
  >
    <Datagrid optimized rowClick="show">
      <TextField source="id" />
      <ChipField source="name" color="primary" />
      <EditButton />
      <ShowButton />
      <CloneButton />
    </Datagrid>
  </List>
);

export default RoleList;
