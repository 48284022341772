import { useState, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  AutocompleteInput,
  SelectInput,
  TextInput,
  TopToolbar,
  ListButton,
  ShowButton,
  CreateButton,
  required,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

// table create options
const maxRows = 10;
const maxCols = 5;
let tableOptions = [];
for (let r = 1; r <= maxRows; r++) {
  for (let c = 1; c <= maxCols; c++) {
    tableOptions.push("newtable_" + r + "_" + c);
  }
}
// quill toolbar options
const toolbarOptions = [
  [{ font: [] }, { size: [] }],
  ["bold", "italic", "underline", "strike"], // toggled buttons
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ header: 1 }, { header: 2 }, "blockquote", "code-block"], // custom button values
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // header size
  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }, { align: [] }], // text direction
  [{ table: tableOptions }, { table: "append-row" }, { table: "append-col" }], // create table
  ["link", "image", "video", "formula"], // media
  ["emoji"],
  ["clean"], // remove formatting button
];

const ParagraphEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const ParagraphEdit = (props) => {
  const [quizChoices, setQuizChoices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/quizzes?limit=9999&offset=0&sort=created_at&order=DESC`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth")}`,
          },
        }
      );
      const data = await response.json();
      setQuizChoices(data);
    };
    fetchData();
  }, []);

  return (
    <Edit actions={<ParagraphEditActions />} {...props}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <AutocompleteInput
          source="quiz"
          optionText="title"
          optionValue="id"
          label="Quiz"
          choices={quizChoices}
          validate={[required()]}
        />
        <SelectInput
          source="type"
          optionText="title"
          optionValue="id"
          label="Type"
          choices={[
            { id: "GRAMMAR_QUESTION", title: "Grammar Question" },
            { id: "TEXT_COMPLETION", title: "Text Completion" },
            { id: "COMPREHENSION", title: "Comprehension" },
            { id: "RESOURCE_LISTENING", title: "Resource Listening" },
          ]}
          validate={[required()]}
        />
        <RichTextInput
          source="content"
          fullWidth
          options={{
            theme: "snow",
            placeholder: "Write paragraph content...",
            modules: {
              table: true,
              toolbar: toolbarOptions,
              clipboard: { matchVisual: false },
              htmlEditButton: {},
              imageDrop: true,
              "emoji-toolbar": true,
              "emoji-textarea": true,
              "emoji-shortname": true,
            },
          }}
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default ParagraphEdit;
