import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ImageField,
  TextInput,
  SearchInput,
  EditButton,
  ShowButton,
  CloneButton,
  useRecordContext,
} from "react-admin";
import ListActions from "./ListActions";

const DescriptionField = (props) => {
  const record = useRecordContext(props);
  return record && record?.description && record?.description.length > 100 ? (
    <span>{record.description.substring(0, 100)}...</span>
  ) : (
    record.description
  );
};

const filters = [
  <SearchInput source="name" alwaysOn />,
  <TextInput label="Description" source="description" />,
];

const VideoList = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      actions={<ListActions sortFields={["id", "name", "created_at"]} />}
      filters={filters}
      debounce={700}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid optimized rowClick="show" size="medium">
        <TextField label="Video id" source="id" />
        <TextField source="name" />
        <DescriptionField source="description" sortable={false} />
        <ImageField source="thumbnail" title="Preview" />
        <DateField
          source="created_at"
          label="Created At"
          showTime
          locales="fr-FR"
        />
        <EditButton />
        <ShowButton />
        <CloneButton />
      </Datagrid>
    </List>
  );
};

export default VideoList;
