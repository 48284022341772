import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  DateField,
  TextInput,
  EditButton,
  ShowButton,
  CloneButton,
  useRecordContext,
} from "react-admin";
import ListActions from "./ListActions";
import parse from "html-react-parser";

const filters = [
  <TextInput label="Search" source="content" alwaysOn />,
  <TextInput label="Type" source="type" />,
  <TextInput label="Quiz Title" source="quiz" />,
];

const ContentField = () => {
  const record = useRecordContext();
  return (
    <div style={{ width: "300px" }}>
      {parse(`${record.content?.substring(0, 150) || ""}...`)}
    </div>
  );
};

const ParagraphList = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    actions={<ListActions sortFields={["id", "quiz", "type", "created_at"]} />}
    filters={filters}
    debounce={700}
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid optimized rowClick="show">
      <TextField source="id" />
      <TextField source="quiz_entity.title" label="Quiz Title" sortBy="quiz" />
      <ChipField
        source="quiz_entity.type"
        label="Quiz Type"
        color="primary"
        sortable={false}
      />
      <ContentField source="content" sortable={false} />
      <ChipField source="type" color="primary" />
      <DateField
        source="created_at"
        label="Created At"
        showTime
        locales="fr-FR"
      />
      <EditButton />
      <ShowButton />
      <CloneButton />
    </Datagrid>
  </List>
);

export default ParagraphList;
