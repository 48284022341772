import {
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  ChipField,
  ImageField,
  DateField,
  SingleFieldList,
  TopToolbar,
  ListButton,
  EditButton,
  CreateButton,
  useRecordContext,
} from "react-admin";
import ReactJson from "react-json-view";

const JsonTextField = ({ label, source }) => {
  const record = useRecordContext();
  return (
    <div style={{ marginTop: "10px" }}>
      <span
        style={{
          color: "rgba(0, 0, 0, 0.54)",
          fontSize: "12px",
          fontFamily: "sans-serif",
        }}
      >
        {label}
      </span>
      <div style={{ padding: "8px 0px 4px" }}>
        {record && record[source] && <ReactJson src={record[source]} />}
      </div>
    </div>
  );
};

const UserShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <CreateButton basePath={basePath} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const UserShow = (props) => {
  return (
    <Show actions={<UserShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField label="Id" source="id" />
        <TextField label="Username" source="username" />
        <TextField label="Email" source="email" />
        <ImageField label="Avatar" source="avatar" />
        <DateField
          source="created_at"
          label="Created At"
          showTime
          locales="fr-FR"
        />
        <DateField
          source="last_login"
          label="Last Login"
          showTime
          locales="fr-FR"
        />
        <ArrayField label="Roles" source="roles">
          <SingleFieldList>
            <ChipField source="name" color="primary" />
          </SingleFieldList>
        </ArrayField>
        <JsonTextField label="Record Data" source="data" />
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
