import React from "react";
import { Admin, Resource, RouteWithoutLayout } from "react-admin";

import { useAuth0 } from "@auth0/auth0-react";
import authProvider from "./authProvider/authProvider";
import dataProvider from "./dataProvider/dataProvider";
import loginPage from "./pages/login";

import {
  AccountCircle as AccountCircleIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Bookmarks as BookmarksIcon,
  LocalOffer as LocalOfferIcon,
  Folder as FolderIcon,
  Group as GroupIcon,
  ViewHeadline as ViewHeadlineIcon,
  OndemandVideo as OndemandVideoIcon,
} from "@material-ui/icons";
import { createMuiTheme } from "@material-ui/core/styles";

import Loading from "./components/Loading";
import Dashboard from "./components/Dashboard";
import UserList from "./components/UserList";
import UserEdit from "./components/UserEdit";
import UserShow from "./components/UserShow";
import RoleList from "./components/RoleList";
import RoleCreate from "./components/RoleCreate";
import RoleEdit from "./components/RoleEdit";
import RoleShow from "./components/RoleShow";
import TagList from "./components/TagList";
import TagCreate from "./components/TagCreate";
import TagEdit from "./components/TagEdit";
import TagShow from "./components/TagShow";
import SubjectList from "./components/SubjectList";
import SubjectCreate from "./components/SubjectCreate";
import SubjectEdit from "./components/SubjectEdit";
import SubjectShow from "./components/SubjectShow";
import QuizList from "./components/QuizList";
import QuizCreate from "./components/QuizCreate";
import QuizEdit from "./components/QuizEdit";
import QuizShow from "./components/QuizShow";
import QuestionList from "./components/QuestionList";
import QuestionCreate from "./components/QuestionCreate";
import QuestionEdit from "./components/QuestionEdit";
import QuestionShow from "./components/QuestionShow";
import ParagraphList from "./components/ParagraphList";
import ParagraphCreate from "./components/ParagraphCreate";
import ParagraphEdit from "./components/ParagraphEdit";
import ParagraphShow from "./components/ParagraphShow";
import VideoList from "./components/VideoList";
import VideoCreate from "./components/VideoCreate";
import VideoEdit from "./components/VideoEdit";
import VideoShow from "./components/VideoShow";

// import richtext editor modules
import Quill from "quill";
import quillTable from "quill-table";
import htmlEditButton from "quill-html-edit-button";
import { ImageDrop } from "quill-image-drop-module";
import * as Emoji from "quill-emoji";

Quill.register(quillTable.TableCell);
Quill.register(quillTable.TableRow);
Quill.register(quillTable.Table);
Quill.register(quillTable.Contain);
Quill.register("modules/table", quillTable.TableModule);
Quill.register("modules/htmlEditButton", htmlEditButton);
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/emoji", Emoji);

// import MUI theme
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: "#C12D2B",
    },
    background: {
      default: "#fff",
    },
  },
  overrides: {
    MuiTableRow: {
      head: {
        backgroundColor: "lightgray",
        "& > th ": {
          color: "black",
          fontWeight: "bold",
        },
      },
    },
  },
});

const App = () => {
  const {
    isLoading,
    isAuthenticated,
    error,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  const customAuthProvider = authProvider({
    loginWithRedirect,
    isAuthenticated,
    logout,
    getAccessTokenSilently,
    getIdTokenClaims,
  });

  const customDataProvider = dataProvider(getAccessTokenSilently);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <Admin
      dataProvider={customDataProvider}
      authProvider={customAuthProvider}
      loginPage={loginPage}
      customRoutes={[
        <RouteWithoutLayout exact path="/welcome" component={Dashboard} />,
      ]}
      theme={theme}
    >
      <Resource
        name="questions"
        list={QuestionList}
        show={QuestionShow}
        create={QuestionCreate}
        edit={QuestionEdit}
        icon={QuestionAnswerIcon}
      />
      <Resource
        name="paragraphs"
        list={ParagraphList}
        show={ParagraphShow}
        create={ParagraphCreate}
        edit={ParagraphEdit}
        icon={ViewHeadlineIcon}
      />
      <Resource
        name="quizzes"
        list={QuizList}
        show={QuizShow}
        create={QuizCreate}
        edit={QuizEdit}
        icon={FolderIcon}
      />
      <Resource
        name="tags"
        list={TagList}
        show={TagShow}
        create={TagCreate}
        edit={TagEdit}
        icon={LocalOfferIcon}
      />
      <Resource
        name="subjects"
        list={SubjectList}
        show={SubjectShow}
        create={SubjectCreate}
        edit={SubjectEdit}
        icon={BookmarksIcon}
      />
      <Resource
        name="videos"
        list={VideoList}
        show={VideoShow}
        create={VideoCreate}
        edit={VideoEdit}
        icon={OndemandVideoIcon}
      />
      <Resource
        name="roles"
        list={RoleList}
        show={RoleShow}
        create={RoleCreate}
        edit={RoleEdit}
        icon={GroupIcon}
      />
      <Resource
        name="users"
        list={UserList}
        show={UserShow}
        edit={UserEdit}
        icon={AccountCircleIcon}
      />
    </Admin>
  );
};

export default App;
