import {
  Show,
  SimpleShowLayout,
  TextField,
  ChipField,
  DateField,
  TopToolbar,
  ListButton,
  EditButton,
  CreateButton,
} from "react-admin";

const SubjectShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <CreateButton basePath={basePath} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const SubjectShow = (props) => {
  return (
    <Show actions={<SubjectShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField label="Id" source="id" />
        <ChipField source="title" color="primary" />
        <DateField
          source="created_at"
          label="Created At"
          showTime
          locales="fr-FR"
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default SubjectShow;
