import * as React from "react";
import {
  List,
  Datagrid,
  DateField,
  TextField,
  EmailField,
  ArrayField,
  SingleFieldList,
  ChipField,
  TextInput,
  SearchInput,
  EditButton,
  ShowButton,
  CloneButton,
} from "react-admin";
import ListActions from "./ListActions";

const filters = [
  <SearchInput source="username" alwaysOn />,
  <TextInput label="Email" source="email" />,
];

const UserList = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    actions={
      <ListActions
        sortFields={["id", "username", "email", "created_at", "last_login"]}
      />
    }
    filters={filters}
    debounce={700}
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid optimized rowClick="show">
      <TextField source="id" />
      <TextField source="username" />
      <EmailField source="email" />
      <ArrayField label="Role" source="roles" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" color="primary" />
        </SingleFieldList>
      </ArrayField>
      <DateField
        source="created_at"
        label="Created At"
        showTime
        locales="fr-FR"
      />
      <DateField
        source="last_login"
        label="Last Login"
        showTime
        locales="fr-FR"
      />
      <EditButton />
      <ShowButton />
      <CloneButton />
    </Datagrid>
  </List>
);

export default UserList;
