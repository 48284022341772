import { useState, useEffect } from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  TopToolbar,
  ListButton,
  EditButton,
  CreateButton,
  useRecordContext,
} from "react-admin";
import YouTube from "@u-wave/react-youtube";

const YoutubePreviewField = () => {
  const record = useRecordContext();

  return (
    <div style={{ margin: "15px 0" }}>
      {record?.id && <YouTube video={record.id} height="480" width="640" />}
    </div>
  );
};

const VideoShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <CreateButton basePath={basePath} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const VideoShow = (props) => {
  return (
    <Show actions={<VideoShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField label="Video id" source="id" />
        <TextField label="Name" source="name" />
        <TextField label="Description" source="description" />
        <DateField
          source="created_at"
          label="Created At"
          showTime
          locales="fr-FR"
        />
        <YoutubePreviewField />
      </SimpleShowLayout>
    </Show>
  );
};

export default VideoShow;
