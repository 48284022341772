const authProvider = ({
  loginWithRedirect,
  isAuthenticated,
  logout,
  getAccessTokenSilently,
  getIdTokenClaims,
}) => ({
  // called when the user attempts to log in
  login: (params) => {
    localStorage.removeItem("auth");
    localStorage.removeItem("user");
    return loginWithRedirect(params);
  },
  // called when the user clicks on the logout button
  logout: () => {
    if (isAuthenticated) {
      localStorage.removeItem("auth");
      localStorage.removeItem("user");
      return logout({ returnTo: window.location.origin });
    }
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth");
      localStorage.removeItem("user");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: async () => {
    if (isAuthenticated) {
      if (!localStorage.getItem("auth")) {
        const token = await getAccessTokenSilently();
        if (token) {
          localStorage.setItem("auth", token);
        }
      }
      return Promise.resolve();
    }
    localStorage.removeItem("auth");
    localStorage.removeItem("user");
    return Promise.reject();
  },
  getIdentity: async () => {
    try {
      if (!localStorage.getItem("user")) {
        const user = await getIdTokenClaims();
        if (user) {
          localStorage.setItem("user", JSON.stringify(user));
        }
      }
      const { sub, name, picture } = JSON.parse(localStorage.getItem("user"));
      return Promise.resolve({ id: sub, fullName: name, avatar: picture });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // authorization - called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
});

export default authProvider;
