import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  ShowButton,
  CreateButton,
  required,
} from "react-admin";

const SubjectEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const SubjectEdit = (props) => (
  <Edit actions={<SubjectEditActions />} {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="title" validate={required()} />
    </SimpleForm>
  </Edit>
);

export default SubjectEdit;
