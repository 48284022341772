import { useState, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SelectInput,
  NumberInput,
  SimpleFormIterator,
  TopToolbar,
  ListButton,
  ShowButton,
  CreateButton,
  required,
} from "react-admin";

const QuizEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const QuizEdit = (props) => {
  const [subjectChoices, setSubjectChoices] = useState([]);
  const [tagChoices, setTagChoices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const responseSubjects = fetch(
        `${process.env.REACT_APP_BACKEND_API}/subjects?limit=9999&offset=0&sort=created_at&order=DESC`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth")}`,
          },
        }
      ).then((response) => response.json());
      const responseTags = fetch(
        `${process.env.REACT_APP_BACKEND_API}/tags?limit=9999&offset=0&sort=created_at&order=DESC`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth")}`,
          },
        }
      ).then((response) => response.json());
      const datas = await Promise.all([responseSubjects, responseTags]);
      setSubjectChoices(datas[0]);
      setTagChoices(datas[1]);
    };
    fetchData();
  }, []);

  return (
    <Edit actions={<QuizEditActions />} {...props}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <TextInput source="title" validate={[required()]} fullWidth />
        <TextInput source="description" validate={[required()]} fullWidth />
        <NumberInput source="time" />
        <SelectInput
          source="subject"
          optionText="title"
          optionValue="id"
          label="Subject"
          choices={subjectChoices}
          validate={[required()]}
        />
        <SelectInput
          source="type"
          optionText="title"
          optionValue="id"
          label="Type"
          choices={[
            { id: "READING", title: "Reading" },
            { id: "LISTENING", title: "Listening" },
          ]}
          validate={[required()]}
        />
        <SelectInput
          source="status"
          optionText="title"
          optionValue="id"
          label="Status"
          choices={[
            { id: "ACTIVE", title: "Active" },
            { id: "INACTIVE", title: "Inactive" },
          ]}
          validate={[required()]}
        />
        <ArrayInput source="tag">
          <SimpleFormIterator>
            <SelectInput
              optionText="title"
              optionValue="id"
              label="Tag"
              choices={tagChoices}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default QuizEdit;
