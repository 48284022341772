import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const Loading = (props) => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        paddingTop: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage:
          "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",
      }}
    >
      <Typography variant="h4" component="h5" wrap style={{ color: "#FFFFFF" }}>
        Loading...
      </Typography>
      <br />
      <CircularProgress thickness={5} size={50} />
    </Box>
  );
};

export default Loading;
