import {
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  FilterButton,
  sanitizeListRestProps,
} from "react-admin";
import SortButton from "./SortButton";

const ListActions = (props) => {
  const { className, maxResults, ...rest } = props;
  const { total } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {props.sortFields ? <SortButton fields={props.sortFields} /> : null}
      <FilterButton filters={props.filters} />
      <CreateButton />
      <ExportButton disabled={total === 0} maxResults={maxResults} />
    </TopToolbar>
  );
};

export default ListActions;
