import { useState, useEffect } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  required,
} from "react-admin";
import { useFormState } from "react-final-form";
import { useDebounce } from "use-debounce";
import YouTube from "@u-wave/react-youtube";

const YoutubePreviewField = () => {
  const { values } = useFormState();
  const [videoId, setVideoId] = useState();
  const [debounceValue] = useDebounce(videoId, 1000);

  useEffect(() => {
    if (values?.id) {
      setVideoId(values.id);
    }
  }, [values.id]);

  return (
    <div style={{ margin: "15px 0" }}>
      {debounceValue && (
        <YouTube video={debounceValue} autoplay height="480" width="640" />
      )}
    </div>
  );
};

const VideoCreateActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const VideoCreate = (props) => {
  return (
    <Create actions={<VideoCreateActions />} {...props}>
      <SimpleForm>
        <TextInput
          label="Video id"
          source="id"
          validate={[required()]}
          fullWidth
        />
        <TextInput source="name" validate={[required()]} fullWidth />
        <TextInput source="description" fullWidth />
        <YoutubePreviewField />
      </SimpleForm>
    </Create>
  );
};

export default VideoCreate;
