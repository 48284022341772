import {
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  BooleanField,
  RichTextField,
  ChipField,
  DateField,
  Datagrid,
  TopToolbar,
  ListButton,
  EditButton,
  CreateButton,
} from "react-admin";

const QuestionShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <CreateButton basePath={basePath} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const QuestionShow = (props) => {
  return (
    <Show actions={<QuestionShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField label="Id" source="id" />
        <TextField label="Quiz Title" source="quiz_entity.title" />
        <RichTextField label="Prompt" source="prompt" />
        <RichTextField label="Explanation" source="explanation" />
        <ChipField
          label="Quiz Type"
          source="quiz_entity.type"
          color="primary"
        />
        <RichTextField
          label="Paragraph content"
          source="paragraph_entity.content"
        />
        <TextField label="Paragraph type" source="paragraph_entity.type" />
        <ArrayField label="Answers" source="answers">
          <Datagrid>
            <TextField source="prompt" label="Prompt" />
            <BooleanField source="is_correct" label="Is Correct" />
          </Datagrid>
        </ArrayField>
        <DateField
          source="created_at"
          label="Created At"
          showTime
          locales="fr-FR"
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default QuestionShow;
