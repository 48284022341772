import { useState, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  ShowButton,
  CreateButton,
  required,
} from "react-admin";
import { useFormState } from "react-final-form";
import YouTube from "@u-wave/react-youtube";

const YoutubePreviewField = () => {
  const { values } = useFormState();

  return (
    <div style={{ margin: "15px 0" }}>
      {values?.id && <YouTube video={values.id} height="480" width="640" />}
    </div>
  );
};

const VideoEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const VideoEdit = (props) => {
  return (
    <Edit actions={<VideoEditActions />} {...props}>
      <SimpleForm>
        <TextInput disabled label="Video id" source="id" />
        <TextInput source="name" validate={[required()]} fullWidth />
        <TextInput source="description" fullWidth />
        <YoutubePreviewField />
      </SimpleForm>
    </Edit>
  );
};

export default VideoEdit;
